<template>
  <v-main class="pt-0 pl-2 pr-2" light>
    <v-row>
      <!-- Info queue -->
      <v-col xl="3" lg="3" md="4" sm="12" sx="12">
        <LeftMenu :listMenu="listMenu" @control-process="controlProcess" />
      </v-col>
      <!-- Queue -->
      <v-col xl="9" lg="9" md="8" sm="12" sx="12">
        <InProcessQueue class="mb-3" v-if="listMenu[0].active" />

        <FinishQueue class="mt-4" v-if="listMenu[1].active" />

        <!-- <PaidQueue class="mt-4" v-if="listMenu[2].active" />

        <StaffQueue class="mt-4" v-if="listMenu[3].active" /> -->
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import LeftMenu from "../../components/commonComponents/Queue/LeftMenu";
import InProcessQueue from "../../components/commonComponents/Queue/InProcessQueue";
import FinishQueue from "../../components/commonComponents/Queue/FinishQueue";
import i18n from "@/plugins/i18n";
// import PaidQueue from "../../components/commonComponents/Queue/PaidQueue";
// import StaffQueue from "../../components/commonComponents/Queue/StaffQueue";
export default {
  components: {
    LeftMenu,
    InProcessQueue,
    FinishQueue,
    // PaidQueue,
    // StaffQueue,
  },

  data: () => {
    return {
      listMenu: [
        {
          id: 1,
          text: i18n.t("Common.InProcess"),
          icon: "mdi-checkbox-blank-circle",
          color: "primary",
          active: true,
        },
        {
          id: 2,
          text: i18n.t("Common.Finish"),
          icon: "mdi-checkbox-blank-circle",
          color: "green",
          active: true,
        },
        // {
        //   id: 3,
        //   text: "Paid",
        //   icon: "mdi-checkbox-blank-circle",
        //   color: "#03A9F4",
        //   active: false,
        // },
        // {
        //   id: 4,
        //   text: "Staff Queue",
        //   icon: "mdi-checkbox-blank-circle",
        //   color: "#dc3545",
        //   active: true,
        // },
      ],
    };
  },

  methods: {
    controlProcess(idMenu) {
      this.listMenu.map(function (i) {
        if (i.id === idMenu) {
          i.active = !i.active;
          return i;
        }
        return i;
      });
    },
  },
};
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #90caf9;
  border-radius: 5%;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2196f3;
  cursor: pointer;
}
</style>