<template>
  <div>
    <!-- Control Viewer -->
    <ControllerMenu :listMenu="listMenu" @update-control="controlProcess" />

    <!-- System information -->
    <v-card class="mt-5">
      <v-card-title>
        <div class="d-flex justify-space-between align-center w-100">
          <div class="title">
            <v-icon large> mdi-cog-sync </v-icon>
            <span
              class="ml-2 text-lg-subtitle-2 text-md-subtitle-2 text-sm-caption"
              >{{ $t('Common.QueueInformation') }}</span
            >
          </div>
          <span class="text-subtitle-2 blue--text">{{ today }}</span>
        </div>
      </v-card-title>

      <!-- Information -->
      <v-sheet>
        <!-- Load report today, how many patients that comes, finish ?, in process ? -->
        <ShortDaily />
      </v-sheet>

      <v-divider class="mx-auto w-50"></v-divider>

      <!-- Basic graph  -->
      <v-sheet width="100%" class="mt-2" v-if="false">
        <HourDaily />
      </v-sheet>
    </v-card>
  </div>
</template>

<script>
import ControllerMenu from "./components/ControllerMenu";
import ReportService from "../../../services/report";
import ShortDaily from "./components/ShortDaily";
import HourDaily from "./components/HourDaily";
export default {
  components: {
    ControllerMenu,
    ShortDaily,
    HourDaily,
  },
  props: ["listMenu"],
  data() {
    return {
      today: "",
    };
  },
  mounted() {
    this.getShortReportDaily();
    this.today = this.currentDate();
  },
  methods: {
    async getShortReportDaily() {
      var result = await ReportService.shortDaily();
      if (result.error) {
        this.showStatus(result.error);
      }
    },

    currentDate() {
      var currentTime = new Date();
      return `${currentTime.getDate()} - ${
        currentTime.getMonth() + 1
      } - ${currentTime.getFullYear()}`;
    },

    controlProcess(idMenu) {
      this.$emit("control-process", idMenu);
    },
  },
};
</script>

<style scoped>
</style>