<template>
  <v-dialog v-model="dialogAdmission" width="1360px">
    <v-card>
      <v-container class="pa-0 ma-0 admission">
        <v-container class="pa-0 ma-0 pl-3 pr-3">
          <AdmissionInformation ref="AdmissionCard" />
        </v-container>
        <v-divider></v-divider>
        <v-container class="pa-0 ma-0 pl-3 pr-3 service-section">
          <ServiceInformation
            ref="ServiceInfoCard"
            :allowUpdateServices="false"
            @onError="showError"
            @onTreeItemsChanged="handleTreeItemsChanged"
          />
        </v-container>
        <v-card-actions class="justify-center pb-5">
          <v-btn text @click="dialogAdmission = false">{{
            $t("Button.Cancel")
          }}</v-btn>
          <v-btn
            :loading="isAddmittingPatient"
            v-show="isAllowAdmission"
            text
            @click="reCreatePatientAdmission"
            class="primary pa-4 ml-10"
            >{{ $t("Button.SAVECHANGES") }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import AdmissionService from "../../../services/admission";
import AdmissionInformation from "./AdmissionInformation";
import ServiceInformation from "./ServiceInformation";

export default {
  props: {},
  components: {
    AdmissionInformation,
    ServiceInformation,
  },
  data: () => ({
    dialogAdmission: false,
    isAddmittingPatient: false,
    isAllowAdmission: false,
    reRenderWidth: true,
  }),
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    async renderReCreateAdmissionDetail(bookingID, patientID, orderItems) {
      this.dialogAdmission = true;
      const timeout = setTimeout(() => {
        // 1. Load admission info: booking info + patient info
        this.$refs.AdmissionCard.reloadAdmissionDetail(bookingID, patientID);
        // 2. reload list of ordered items
        this.$refs.ServiceInfoCard.reloadOrderItemsInformation(
          orderItems,
          this.reRenderWidth
        );
        clearTimeout(timeout);
      }, 200);
    },
    handleTreeItemsChanged(treeItems) {
      if (!treeItems || !treeItems.length) {
        this.isAllowAdmission = false;
        return;
      }
      this.isAllowAdmission = true;
    },
    async reCreatePatientAdmission() {
      this.isAddmittingPatient = true;
      var booking = this.$refs.AdmissionCard.booking;
      var personalInfo = this.$refs.AdmissionCard.personalInfo;
      let admission = {
        clinicID: booking.clinicID,
        patientID: booking.patientID,
        bookingID: booking.bookingID,
        admissionType: booking.bookingType,
        patientType: personalInfo.patientType || 0,
      };
      let promotionID = this.$refs.ServiceInfoCard.promotionID || null;
      let treeItems = this.$refs.ServiceInfoCard.treeItems || [];
      let orderItems = [];
      treeItems.forEach((i) => {
        // add parent item => parentServiceID = null
        orderItems.push({
          itemName: i.name,
          isFree: i.isFree || false,
          targetID: i.itemID,
          typeID: i.type,
          staffPerformID: i.doctorID || null,
          roomID: i.roomID || null,
          parentServiceID: null,
          promotionID: i.promotionIDs.find((id) => id == promotionID) || null,
        });
        // add children item => parentServiceID = serviceID
        // but promotionID = null because price is included
        if (i.children && i.children.length) {
          i.children.forEach((child) => {
            orderItems.push({
              itemName: child.name,
              isFree: child.isFree || false,
              targetID: child.itemID,
              typeID: child.type,
              staffPerformID: child.doctorID || null,
              roomID: child.roomID || null,
              parentServiceID: child.serviceID || null,
              promotionID: null,
            });
          });
        }
      });
      var result = await AdmissionService.updateOrderItemsIsFree(
        admission,
        orderItems
      );
      this.isAddmittingPatient = false;
      if (result.error) {
        this.showError("Can not re-create admission. Please try again later.");
        return;
      }
      var summaryID = result;
      this.$emit("onSuccess", summaryID);
      this.dialogAdmission = false;
      return summaryID;
    },
  },
};
</script>

<style scoped>
.text-space {
  letter-spacing: 0.5px;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
.admission.container {
  max-width: 1360px !important;
}
.admission .container {
  max-width: 1360px !important;
}
.admission .service-section {
  /* max-height: 670px; */
  /* overflow-y: scroll; */
}
</style>