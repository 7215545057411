<template>
  <v-card style="position: relative">
    <v-card-title class="pt-5">
      <div class="p-2 d-flex justify-space-between align-center w-100">
        <div>
          <span>{{ $t('Common.Finish') }}</span>
          <span class="ml-4">
            [<b class="blue--text">
              <number
                :from="0"
                :to="countPatient"
                deplay="1"
                :duration="2"
              /> </b
            >]
          </span>
        </div>

        <v-col col="3" md="3" sm="5">
          <SearchPatient @search-Item="search" />
        </v-col>

        <div class="close">
          <v-icon size="16">mdi-close</v-icon>
        </div>
      </div>
    </v-card-title>

    <v-card>
      <!-- Loadding -->
      <v-progress-linear indeterminate color="cyan" v-if="loading">
      </v-progress-linear>

      <!-- Data showing -->
      <v-simple-table v-else class="finish-content">
        <thead class="font-weight-bold">
          <tr>
            <td v-for="(item, index) in titles" :key="index">{{ item }}</td>
          </tr>
        </thead>

        <tbody v-for="(itemPatient, index) in listPatient" v-bind:key="index">
          <tr>
            <td class="order">{{ index + 1 }}</td>
            <td>
              <span
                class="patient-name"
                @click="openPatientProfilePopup(itemPatient.patientID)"
              >
                {{ itemPatient.fullName }}
              </span>
            </td>
            <td>{{ itemPatient.patientID }}</td>
            <td>{{ itemPatient.age }}</td>
            <td>
              <GenderItem :genderType="itemPatient.gender" />
            </td>
            <td>{{ formatDate(itemPatient.startTime) }}</td>
            <td>{{ formatDate(itemPatient.endTime) }}</td>
            <td>
              <v-btn color="primary" x-small fab>
                <v-icon dark>mdi-cash-usd-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <!-- Display empty -->
      <div v-if="!status.show">
        <div
          style="padding: 10px 15px"
          class="w-100"
          v-if="listPatient.length == 0"
        >
          <span class="text-h6">{{ $t('EmptyData.ListEmpty') }}</span>
        </div>
      </div>
    </v-card>
    <v-alert type="error" v-if="status.show">
      {{ status.message }}
    </v-alert>
    <PatientProfilePopup ref="PatientProfilePopup" />
  </v-card>
</template>

<script>
import QueueService from "../../../services/queue";
import SearchPatient from "./components/InputSearchName";
import GenderItem from "./components/GenderItem";
import PatientProfilePopup from "../PatientInfo/PatientProfilePopup";
import { transliterateChar } from "../../../plugins/helper";
import moment from "moment";
import i18n from "@/plugins/i18n";

export default {
  watch: {},
  components: {
    SearchPatient,
    GenderItem,
    PatientProfilePopup,
  },

  data: () => {
    return {
      status: {
        type: "error",
        show: false,
        message: "",
      },
      countPatient: 0,
      loading: true,
      titles: [
        i18n.t("Common.Order"),
        i18n.t("Patient.Name"),
        i18n.t("Patient.PID"),
        i18n.t("Patient.Age"),
        i18n.t("Patient.Gender"),
        i18n.t("Common.TimeStart"),
        i18n.t("Common.TimeEnd"),
        i18n.t("Common.Action"),
      ],
      listPatient: [],
      source: {
        amount: 0,
        listPatient: [],
      },
    };
  },

  async mounted() {
    this.getFinishQueue();
  },

  computed: {},

  methods: {
    // show error
    showError(message) {
      this.$toast.error(message);
    },
    cheat() {
      this.loading = false;
      this.countPatient = this.itemInProcess.length;
    },
    // get finish queue
    async getFinishQueue() {
      this.loading = true;
      var result = await QueueService.getFinishQueue();

      if (result.error) {
        this.showError(result.error);
        this.loading = false;
        return;
      }
      this.status.show = false;
      this.loading = false;
      this.listPatient = result.listPatient;
      this.countPatient = result.amount;
      this.source = {
        amount: result.amount,
        listPatient: result.listPatient,
      };
    },
    // search patient name
    search(name) {
      if (name.length > 0) {
        name = transliterateChar(name).toLowerCase(); // convert 'Văn Chí' to 'van chi'
        console.log(name);
        var filter = this.source.listPatient.filter(function (item) {
          let fullName = transliterateChar(item.fullName).toLowerCase();
          return (
            fullName.includes(name) || item.patientID.toString().includes(name)
          );
        });
        this.listPatient = filter;
      } else {
        this.listPatient = this.source.listPatient;
      }
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY hh:mm:ss");
    },
    openPatientProfilePopup(patientID) {
      this.$refs.PatientProfilePopup.openPopup(patientID);
    },
  },
};
</script>

<style scoped>
.finish-content {
  max-height: 50vh;
  overflow: auto;
}
.finish-content tr {
  border-bottom: 1px solid grey;
}

.finish-content .order {
  font-weight: bold;
  text-decoration: underline;
}

.finish-content tr:hover {
  cursor: pointer;
}
.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 5px;
  cursor: pointer;
}
</style>