<template>
  <v-card>
    <v-card-title>
      <v-icon large>mdi-microsoft-xbox-controller-menu</v-icon>
      <span class="ml-2">{{ $t('Common.Controller') }}</span>
    </v-card-title>
    <v-sheet style="padding: 0px 10px">
      <v-list flat>
        <v-list-item-group>
          <v-list-item
            v-for="(item, index) in listMenu"
            :key="index"
            @click="clickItem(item)"
            exact-active-class="active-component"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon" :color="item.color"></v-icon>
            </v-list-item-icon>
            <v-list-item-action
              class="d-flex justify-space-between align-center w-100"
            >
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-action>

            <v-list-item-icon v-if="item.active">
              <v-icon color="success">mdi-check</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-sheet>
  </v-card>
</template>

<script>
export default {
  props: ["listMenu"],
  data() {
    return {};
  },

  mounted() {},
  methods: {
    clickItem(object) {
      this.$emit("update-control", object.id);
    },
  },
};
</script>

<style scoped>
.active-component {
  background-color: #2196f3;
  color: #ffffff;
}
</style>