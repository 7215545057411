<template>
  <div class="graph-hour-daily">
    <label class="text-caption pl-4">Today</label>
    <div class="mt-2">
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="cyan"
      ></v-progress-linear>
      <line-chart :chartData="datacollection" style="max-height: 200px">
      </line-chart>

      <!-- Show error if exist-->
      <v-alert
        :type="status.type || 'error'"
        width="100%"
        icon="mdi-cloud-alert"
        dismissible
        v-model="status.show"
        transition="scale-transition"
      >
        {{ status.message }}
      </v-alert>
    </div>
  </div>
</template>

<script>
import ReportService from "../../../../services/report";
import LineChart from "../../Charts/LineChart";
export default {
  components: {
    LineChart,
  },
  data: () => {
    return {
      status: {
        type: "",
        show: false,
        message: "",
      },
      loading: false,
      datacollection: null,
    };
  },
  async mounted() {
    this.getHourDaily();
  },

  methods: {
    showStatus(msg) {
      this.status.type = "error";
      this.status.show = true;
      this.status.message = msg;
    },

    async getHourDaily() {
      this.loading = true;
      var result = await ReportService.hourDaily();
      if (result.error) {
        this.showStatus(result.error);
        return;
      }
      console.log(result);

      this.status.show = false;

      if (result.length > 0) {
        // set labels
        var chartData = {
          labels: [],
          datasets: [],
        };

        // set default for dataset
        var datasets = {
          label: "Amount patients",
          data: [],
          backgroundColor: "transparent",
          borderColor: "rgba(1, 116, 188, 0.50)",
          pointBackgroundColor: "#E53935",
        };

        result.forEach((item) => {
          chartData.labels.push(item.timeAMPM);
          datasets.data.push(item.amount);
        });

        chartData.datasets.push(datasets);
        this.datacollection = chartData;
      }

      this.loading = false;
    },
  },
};
</script>

<style>
#canvas-graph-hour div:first {
  max-height: 200px;
}
</style>
