<template>
  <div id="short-daily">
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="cyan"
    ></v-progress-linear>

    <v-card v-else>
      <v-col col="12">
        <div class="item">
          <span class="text-subtitle-1">{{ $t('Patient.TotalPatient') }}</span>
          <span class="number text-lg-h6 text-sm-h5 text-sx-h4">
            <number
              :from="0"
              :to="shortDaily.total"
              :deplay="2"
              :duration="2"
            />
          </span>
        </div>
      </v-col>
      <v-col col="12">
        <div class="item">
          <span class="text-subtitle-1">{{ $t('Common.InProcess') }}</span>
          <span class="number blue--text text-lg-h6 text-sm-h5 text-sx-h4">
            <number
              :from="0"
              :to="shortDaily.inProcess"
              :deplay="2"
              :duration="2"
            />
          </span>
        </div>
      </v-col>
      <v-col col="12">
        <div class="item">
          <span class="text-subtitle-1">{{ $t('Common.Finish') }}</span>
          <span class="number green--text text-lg-h6 text-sm-h5 text-sx-h4">
            <number
              :from="0"
              :to="shortDaily.finish"
              :deplay="2"
              :duration="2"
            />
          </span>
        </div>
      </v-col>
      <v-col col="12">
        <div class="item">
          <span class="text-subtitle-1">{{ $t('Common.Paid') }}</span>
          <span class="number green--text text-lg-h6 text-sm-h5 text-sx-h4">
            <number :from="0" :to="shortDaily.paid" :deplay="2" :duration="2" />
          </span>
        </div>
      </v-col>
    </v-card>

    <v-alert
      :type="status.type || 'error'"
      width="100%"
      icon="mdi-cloud-alert"
      dismissible
      v-model="status.show"
      transition="scale-transition"
    >
      {{ status.message }}
    </v-alert>
  </div>
</template>

<script>
import ReportService from "../../../../services/report";
export default {
  data: () => {
    return {
      loading: true,
      status: {
        type: "error",
        show: false,
        message: "",
      },

      shortDaily: {
        total: 0,
        inProcess: 0,
        finish: 0,
        paid: 0,
      },
    };
  },

  async mounted() {
    this.getShortDaily();
  },

  methods: {
    showStatus(msg) {
      this.status.type = "error";
      this.status.message = msg;
      this.status.show = true;
    },

    async getShortDaily() {
      this.loading = true;
      var result = await ReportService.shortDaily();
      if (result.error) {
        this.showStatus(result.error);
        this.loading = false;
        return;
      }
      this.loading = false;
      // change status for status
      this.status.show = false;

      this.shortDaily.total = result.total;
      this.shortDaily.inProcess = result.inProcess;
      this.shortDaily.finish = result.finish;
      this.shortDaily.paid = result.paid;
    },
  },
};
</script>

<style scoped>
#short-daily {
  display: flex;
  flex-direction: column;
  width: 100%;
}
#short-daily .item {
  width: 100%;
  padding: 5px 5px;
  display: flex;
  justify-content: space-between !important;
}

#short-daily .number {
  font-weight: bold;
}
</style>