var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"position":"relative"}},[_c('v-card-title',{staticClass:"pt-5"},[_c('div',{staticClass:"p-2 d-flex justify-space-between align-center w-100"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("Common.InProcess")))]),_c('span',{staticClass:"ml-4"},[_vm._v(" ["),_c('b',{staticClass:"blue--text"},[_c('number',{attrs:{"from":0,"to":_vm.countPatient,"deplay":"1","duration":2}})],1),_vm._v("] ")])]),_c('v-col',{attrs:{"col":"3","md":"3","sm":"5"}},[_c('SearchPatient',{on:{"search-Item":_vm.search}})],1),_c('div',{staticClass:"close"},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-close")])],1)],1)]),_c('v-card',[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"cyan"}}):_vm._e(),_c('v-simple-table',{attrs:{"id":"in-process-content"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"font-weight-bold"},[_c('tr',_vm._l((_vm.titles),function(item,index){return _c('td',{key:index,on:{"click":item.actions}},[_vm._v(" "+_vm._s(item.title)+" "),_c('v-icon',[_vm._v(_vm._s(item.icon))])],1)}),0)]),_c('tbody',_vm._l((_vm.listPatient),function(itemPatient,index){return _c('tr',{key:index,class:itemPatient.episodeRecordID == _vm.epRFromPathID
                ? 'hightLight'
                : 'noneHightLight'},[_c('td',{staticClass:"order"},[_vm._v(_vm._s(itemPatient.queueNumber))]),_c('td',[_c('v-tooltip',{attrs:{"top":"","content-class":"tooltip-avatar"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"patient-name",on:{"click":function($event){return _vm.openPatientProfilePopup(itemPatient.patientID)}}},'span',attrs,false),on),[_vm._v(" "+_vm._s(itemPatient.fullName)+" ")])]}}],null,true)},[_c('div',{staticClass:"patient-avatar"},[(itemPatient.avatar)?_c('img',{attrs:{"src":itemPatient.avatar,"alt":"Patient avatar"}}):_c('span',{domProps:{"innerHTML":_vm._s(itemPatient.fullName)}})])])],1),_c('td',[_vm._v(_vm._s(itemPatient.patientID))]),_c('td',[_vm._v(_vm._s(itemPatient.age))]),_c('td',[_c('GenderItem',{attrs:{"genderType":itemPatient.gender}})],1),_c('td',[_c('TimeProcess',{attrs:{"minutes":itemPatient.minutes}})],1),_c('td',[_c('div',{staticClass:"patient-service"},[_vm._l((itemPatient.listItems),function(itemService,index){return _c('div',{key:index,staticClass:"item"},[_c('v-tooltip',{attrs:{"top":"","nudge-left":"40px","open-on-click":"","content-class":"active-tooltip-item-name"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"target-item-name text-subtitle-2"},'span',attrs,false),on),[_vm._v(" "+_vm._s(itemService.targetName)+" ")])]}}],null,true)},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(itemService.targetName))])]),_c('StatusProcess',{staticClass:"status",attrs:{"completeID":itemService.completeID}}),_c('span',{staticClass:"text--subtitle doctor-handle"},[_vm._v(_vm._s(itemService.staffPerform))]),_c('span',{staticClass:"text--subtitle doctor-room"},[_vm._v(_vm._s(itemService.roomName))])],1)}),(itemPatient.listItems.length == 0)?_c('div',{staticClass:"item"},[_c('span',{staticClass:"text-subtitle red--text"},[_vm._v("Please asign item!")])]):_vm._e()],2)]),_c('td',{staticStyle:{"width":"50px"}},[_c('v-btn',{staticClass:"mt-5",attrs:{"icon":""},on:{"click":function($event){return _vm.viewEpisodeRecordDetail(itemPatient.episodeRecordID)}}},[_c('v-icon',[_vm._v(" mdi-border-color ")])],1),_c('br'),(_vm.isAllowDeleteEpisode)?_c('v-btn',{staticClass:"mt-2",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteEpisode(itemPatient)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1):_vm._e(),(_vm.isAllowDeleteEpisode)?_c('v-btn',{staticClass:"mt-2",attrs:{"icon":""},on:{"click":function($event){return _vm.handlePrintNumberOrder(
                    itemPatient.patientID,
                    itemPatient.episodeRecordID,
                    itemPatient.queueNumber
                  )}}},[_c('v-icon',[_vm._v(" mdi-printer ")])],1):_vm._e()],1)])}),0)]},proxy:true}])}),(_vm.status.show)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.status.message)+" ")]):_vm._e()],1),_c('PatientProfilePopup',{ref:"PatientProfilePopup"}),_c('DialogConfirm',{ref:"confirmDeleteAdmission",attrs:{"message":_vm.$t('DialogConfirm.DeleteThisAdmission')}}),_c('ReCreateAdmissionForm',{ref:"ReCreateAdmissionForm",on:{"onSuccess":_vm.handleReCreateAdmissionSuccess}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }