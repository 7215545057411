<template>
  <v-card style="position: relative">
    <v-card-title class="pt-5">
      <div class="p-2 d-flex justify-space-between align-center w-100">
        <div>
          <span>{{ $t("Common.InProcess") }}</span>
          <span class="ml-4">
            [<b class="blue--text">
              <number
                :from="0"
                :to="countPatient"
                deplay="1"
                :duration="2"
              /> </b
            >]
          </span>
        </div>

        <v-col col="3" md="3" sm="5">
          <SearchPatient @search-Item="search" />
        </v-col>

        <div class="close">
          <v-icon size="16">mdi-close</v-icon>
        </div>
      </div>
    </v-card-title>

    <!-- Content of inProcess -->
    <v-card>
      <!-- Loading bar -->
      <v-progress-linear
        indeterminate
        color="cyan"
        v-if="loading"
      ></v-progress-linear>

      <!-- Showing data -->
      <v-simple-table id="in-process-content">
        <template v-slot:default>
          <thead class="font-weight-bold">
            <tr>
              <td
                v-for="(item, index) in titles"
                :key="index"
                @click="item.actions"
              >
                {{ item.title }}
                <v-icon>{{ item.icon }}</v-icon>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr
              :class="
                itemPatient.episodeRecordID == epRFromPathID
                  ? 'hightLight'
                  : 'noneHightLight'
              "
              v-for="(itemPatient, index) in listPatient"
              :key="index"
            >
              <td class="order">{{ itemPatient.queueNumber }}</td>
              <td>
                <v-tooltip top content-class="tooltip-avatar">
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                      class="patient-name"
                      @click="openPatientProfilePopup(itemPatient.patientID)"
                    >
                      {{ itemPatient.fullName }}
                    </span>
                  </template>
                  <div class="patient-avatar">
                    <img
                      v-if="itemPatient.avatar"
                      :src="itemPatient.avatar"
                      alt="Patient avatar"
                    />
                    <span v-else v-html="itemPatient.fullName"></span>
                  </div>
                </v-tooltip>
              </td>
              <td>{{ itemPatient.patientID }}</td>
              <td>{{ itemPatient.age }}</td>
              <td>
                <GenderItem :genderType="itemPatient.gender" />
              </td>
              <td>
                <TimeProcess :minutes="itemPatient.minutes" />
              </td>
              <!-- List Service that belongs to the patient -->
              <td>
                <div class="patient-service">
                  <div
                    class="item"
                    v-for="(itemService, index) in itemPatient.listItems"
                    :key="index"
                  >
                    <v-tooltip
                      top
                      nudge-left="40px"
                      open-on-click
                      content-class="active-tooltip-item-name"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          class="target-item-name text-subtitle-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ itemService.targetName }}
                        </span>
                      </template>
                      <span class="text-subtitle-2">{{
                        itemService.targetName
                      }}</span>
                    </v-tooltip>
                    <StatusProcess
                      v-bind:completeID="itemService.completeID"
                      class="status"
                    />
                    <span class="text--subtitle doctor-handle">{{
                      itemService.staffPerform
                    }}</span>
                    <span class="text--subtitle doctor-room">{{
                      itemService.roomName
                    }}</span>
                  </div>
                  <!-- Announce that patient was not assigned the service -->
                  <div class="item" v-if="itemPatient.listItems.length == 0">
                    <span class="text-subtitle red--text"
                      >Please asign item!</span
                    >
                  </div>
                </div>
              </td>
              <td style="width: 50px">
                <v-btn
                  @click="viewEpisodeRecordDetail(itemPatient.episodeRecordID)"
                  icon
                  class="mt-5"
                >
                  <v-icon> mdi-border-color </v-icon>
                </v-btn>
                <br />
                <v-btn
                  v-if="isAllowDeleteEpisode"
                  @click="deleteEpisode(itemPatient)"
                  icon
                  class="mt-2"
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
                <v-btn
                  v-if="isAllowDeleteEpisode"
                  @click="
                    handlePrintNumberOrder(
                      itemPatient.patientID,
                      itemPatient.episodeRecordID,
                      itemPatient.queueNumber
                    )
                  "
                  icon
                  class="mt-2"
                >
                  <v-icon> mdi-printer </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <!-- Alert error  -->
      <v-alert type="error" v-if="status.show">
        {{ status.message }}
      </v-alert>
    </v-card>
    <PatientProfilePopup ref="PatientProfilePopup" />
    <DialogConfirm
      ref="confirmDeleteAdmission"
      :message="$t('DialogConfirm.DeleteThisAdmission')"
    />
    <ReCreateAdmissionForm
      ref="ReCreateAdmissionForm"
      @onSuccess="handleReCreateAdmissionSuccess"
    />
  </v-card>
</template>

<script>
import QueueService from "../../../services/queue";
import TimeProcess from "./components/TimeProcess";
import SearchPatient from "./components/InputSearchName";
import StatusProcess from "./components/StatusProcess";
import GenderItem from "./components/GenderItem";
import PatientProfilePopup from "../PatientInfo/PatientProfilePopup";
import ReCreateAdmissionForm from "../Admission/ReCreateAdmissionForm";
import DialogConfirm from "../../DialogConfirm";
import { transliterateChar } from "../../../plugins/helper";
import AdmissionService from "../../../services/admission";
import _ from "lodash";

export default {
  components: {
    SearchPatient,
    TimeProcess,
    StatusProcess,
    GenderItem,
    PatientProfilePopup,
    DialogConfirm,
    ReCreateAdmissionForm,
  },
  data() {
    return {
      status: {
        type: "error",
        show: false,
        message: "",
      },
      eHightLight: false,
      loading: true,
      countPatient: 0,
      toggleReverse: false,
      titles: [
        {
          title: this.$t("Common.Order"),
          sortable: true,
          icon: "mdi-menu-down",
          actions: () => {
            var arr = null;
            if (this.toggleReverse == false) {
              this.toggleReverse = true;
              arr = _.sortBy(this.listPatient, ["queueNumber"]);
              this.listPatient = arr;
              this.titles[0].icon = "mdi-menu-up";
            } else {
              arr = _.sortBy(this.listPatient, ["queueNumber"]).reverse();
              this.listPatient = arr;
              this.toggleReverse = false;
              this.titles[0].icon = "mdi-menu-down";
            }
          },
        },
        {
          title: this.$t("Patient.Name"),
          sortable: false,
          actions: () => {},
        },
        {
          title: this.$t("Patient.PID"),
          sortable: false,
          actions: () => {},
        },
        {
          title: this.$t("Patient.Age"),
          sortable: false,
          icon: "mdi-menu-down",
          actions: () => {
            var arr = null;
            if (this.toggleReverse == false) {
              this.toggleReverse = true;
              arr = _.sortBy(this.listPatient, ["age"]);
              this.listPatient = arr;
              this.titles[3].icon = "mdi-menu-up";
            } else {
              arr = _.sortBy(this.listPatient, ["age"]).reverse();
              this.listPatient = arr;
              this.toggleReverse = false;
              this.titles[3].icon = "mdi-menu-down";
            }
          },
        },
        {
          title: this.$t("Patient.Gender"),
          sortable: false,
          actions: () => {},
        },
        {
          title: this.$t("Common.Minutes"),
          sortable: false,
          icon: "mdi-menu-down",
          actions: () => {
            var arr = null;
            if (this.toggleReverse == false) {
              this.toggleReverse = true;
              arr = _.sortBy(this.listPatient, ["minutes"]);
              this.listPatient = arr;
              this.titles[5].icon = "mdi-menu-up";
            } else {
              arr = _.sortBy(this.listPatient, ["minutes"]).reverse();
              this.listPatient = arr;
              this.toggleReverse = false;
              this.titles[5].icon = "mdi-menu-down";
            }
          },
        },
        {
          title: this.$t("Service.Service"),
          sortable: false,
          actions: () => {},
        },
        {
          title: this.$t("Common.Action"),
          sortable: false,
          actions: () => {},
        },
      ],
      listPatient: [],
      source: {
        amount: 0,
        listPatient: [],
      },
      epRFromPathID: this.$route.params.episodeRecordID,
    };
  },
  computed: {
    isAllowDeleteEpisode() {
      return this.$store.getters.isRoot || this.$store.getters.isAdmin;
    },
  },
  created() {},
  async mounted() {
    this.getOverviewQueue();
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    handlePrintNumberOrder(paID, epRID, number) {
      let patientID = paID;
      let episodeRecordID = epRID;
      let numberOrder = number;
      let srcLinkPrint = `/orderNumber-print/${patientID}/${episodeRecordID}/${numberOrder}`;
      window.open(srcLinkPrint, "Patient Order", "width=800, height=500");
    },
    loadingFnc() {
      this.loading = false;
    },
    handleReCreateAdmissionSuccess() {
      this.getOverviewQueue();
    },
    async deleteEpisode({ episodeID, episodeRecordID }) {
      if (await this.$refs.confirmDeleteAdmission.confirm()) {
        this.loading = true;
        await AdmissionService.deleteEpisode(episodeID);
        this.loading = false;
        await this.getOverviewQueue();
        this.sockets.common.invoke("CancelEpisode", episodeID);
        this.sockets.common.invoke("CancelEpisodeRecord", episodeRecordID);
      }
    },
    async viewEpisodeRecordDetail(episodeRecordID) {
      this.loading = true;
      var result = await AdmissionService.getEpisodeRecordSummary(
        episodeRecordID
      );
      this.loading = false;
      if (!result || result.error) {
        this.showError(
          "Can not retriece queue data at the moment! Please try again later."
        );
      }
      var { bookingID, patientID, orderItems } = result;
      this.$refs.ReCreateAdmissionForm.renderReCreateAdmissionDetail(
        bookingID,
        patientID,
        orderItems
      );
    },
    async getOverviewQueue() {
      this.loading = true;
      let result = await QueueService.getOverviewQueue();
      if (result.error) {
        this.showError("Can not retrieve data!");
        return;
      }
      this.loading = false;
      this.status.show = false;
      this.listPatient = result.listPatient;
      this.countPatient = result.amount;
      this.source = {
        amount: result.amount,
        listPatient: result.listPatient,
      };
    },
    search(name) {
      if (name.length > 0) {
        name = transliterateChar(name).toLowerCase();

        var filter = this.source.listPatient.filter(function (item) {
          console.log(item);
          let fullName = transliterateChar(item.fullName).toLowerCase();
          return (
            fullName.includes(name) || item.patientID.toString().includes(name)
          );
        });

        this.listPatient = filter;
      } else {
        this.listPatient = this.source.listPatient;
      }
    },
    openPatientProfilePopup(patientID) {
      this.$refs.PatientProfilePopup.openPopup(patientID);
    },
  },
};
</script>

<style scoped>
.patient-name:hover {
  color: #1e88e5;
}

.fix_col1 {
  width: 9% !important;
}

.hightLight {
  background-color: #fff2ac;
  background-image: linear-gradient(to right, #79b7ed 0%, #ffffff 100%);
  animation: shake 0.5s;
}

.noneHightLight {
  background-color: none;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  height: 100%;
  padding: 3px;
}

.item > .target-item-name {
  width: 35%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item > .doctor-handle {
  width: 20%;
  text-align: left;
}

.item > .doctor-room {
  width: 10%;
  text-align: left;
}

.active-tooltip-item-name {
  background: #000000;
  color: #ffffff;
  font-weight: bold;
}

#in-process-content tr {
  cursor: pointer;
}
.pa-order {
  font-weight: bold;
  text-decoration: underline;
}
.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 5px;
  cursor: pointer;
}

.close:hover > i {
  color: red;
}
#in-process-content {
  max-height: 70vh;
  overflow: auto;
}

.patient-service {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3px 0;
}
.patient-service > .item {
  width: 100%;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}

#in-process-content .order {
  font-weight: bold;
  text-decoration: underline;
}
#in-process-content td {
  border-bottom: 1px solid #eaeaea;
}

.staff-perform-room {
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
}

.content-tooltip {
  background-color: black;
}

.tooltip-avatar {
  cursor: pointer;
  opacity: 1;
  background-color: black;
  padding: 5px;
  border-radius: 5%;
}

.patient-avatar {
  max-height: 80px;
  max-width: 80px;
  box-sizing: border-box;
  object-fit: cover;
  display: flex;
  justify-content: center;
}
.patient-avatar img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

@keyframes shake {
  0% {
    transform: translate(0.5px, 0.5px) rotate(0deg);
  }
  10% {
    transform: translate(-0.5px, -1px) rotate(-1deg);
  }
  20% {
    transform: translate(-1px, 0px) rotate(1deg);
  }
}
</style>