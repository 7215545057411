<template>
  <div class="d-flex justify-end container-search-input">
    <div
      class="justify-end"
      v-bind:class="{
        'search-input-active': activeSearch,
        'search-input': deActiveSearch,
      }"
    >
      <input
        type="text"
        :value="patientName"
        class="search-text"
        @focus="focusSearch"
        @keyup="search"
        v-click-outside="blurSearch"
      />
      <v-icon v-if="deActiveSearch">mdi-account-search</v-icon>
      <v-icon class="icon-action" v-else @click="removeName">mdi-close</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeSearch: false,
      deActiveSearch: true,
      patientName: "",
    };
  },
  methods: {
    search(e) {
      this.patientName = e.target.value;
      this.$emit("search-Item", this.patientName);
    },
    focusSearch() {
      this.activeSearch = true;
      this.deActiveSearch = false;
    },
    blurSearch() {
      this.activeSearch = false;
      this.deActiveSearch = true;
    },
    removeName() {
      this.patientName = "";
    },
  },
};
</script>

<style scoped>
.container-search-input {
  border-radius: 5px;
  padding: 0 5px;
}
.search-input,
.search-input-active {
  padding: 2px 5px;
  margin-left: 3px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  align-content: flex-end;
  border-radius: 5px;
  transition: 0.5s width;
  width: 60%;
}

.search-input {
  background-color: #ebecf0;
  color: #000000;
}

.search-input-active {
  width: 100%;
  border: 1.2px solid #ebecf0;
}

.search-text {
  width: 50%;
  background-color: #ebecf0;
  border: none;
  box-shadow: none;
  font-size: 14px;
  margin-right: 5px;
  transition: 0.5s width;
  text-align: center;
}

.search-text:focus {
  border: none;
  outline: none;
  background-color: #ffffff;
  width: 100%;
}
.search-text:blur {
  width: 150px;
}
.icon-action {
  cursor: pointer;
}
.icon-action:hover {
  color: red;
}
</style>